<template>
  <div class="MainContainer">
    Redirecting...
  </div>
</template>

<script>
export default {
  created() {
    this.$router.push("/home");
  }
};
</script>
